import { Injectable } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { Observable, from, Subject, Subscriber } from 'rxjs';
import { SiteReviewService } from 'app/services/site-review.service';
import { ChatRecord } from '../model/models';
import { HubConnection } from '@microsoft/signalr';
import { DataStoreService } from 'app/services/datastore.service';

@Injectable()
export class ChatterService {
  public SERVER;
  public ENDPOINT;
  public hubConnection: HubConnection;
  private username;
  private emitter = new Subscriber();
  private emitter2 = new Subscriber();
  private emitter3 = new Subscriber();
  private emitter4 = new Subscriber();
  private emitter5 = new Subscriber();
  public messageStream
  public jobMasterUpdate;
  public vehicleListUpdate;
  public repairOrderListUpdate;
  public eventListUpdate;
  public sessionID = '';

  public jobMasterIsUpdting = false;

  constructor(private db: SiteReviewService, private dataStore: DataStoreService) {

    this.SERVER = this.db.apiServer;
    this.ENDPOINT = `${this.SERVER}/chat`;
    this.messageStream = new Observable(e => this.emitter = e);
    this.jobMasterUpdate = new Observable(e => this.emitter2 = e);
    this.vehicleListUpdate = new Observable(e=> this.emitter3 = e);
    this.repairOrderListUpdate = new Observable(e=> this.emitter4 = e);
    this.eventListUpdate = new Observable(e=> this.emitter5 = e);
 
    let lc = localStorage.getItem("session");
    let session = JSON.parse(lc);
    //console.log('session', session)
    this.sessionID = db.sessionId;


    this.username = session.idTokenPayload.name

    this.setupConnection();
  }

  post(chatRecord: ChatRecord) {
    chatRecord.sid = this.sessionID;
    chatRecord.from = this.username;
    console.log('sending message', chatRecord);
    this.hubConnection.invoke('Send', this.username, JSON.stringify(chatRecord));
  }
  setupConnection() {



    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(this.ENDPOINT, {
        //skipNegotiation: true,
        //  transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start().then(() => {
      if (this.emitter) {
        // console.log('Successfully connected to chat server');
        let cr = new ChatRecord('All', [], `${this.username} has logged in`, '', this.sessionID);
        this.post(cr)
        //this.emitter.next(['Connected to chat server!']);
      }

    }).catch(function (err) {
      return console.error(err.toString());
    });

    this.hubConnection.onreconnecting(error => {
      console.assert(this.hubConnection.state === signalR.HubConnectionState.Reconnecting);
      this.emitter.next([`Connection lost due to error "${error}". Reconnecting.`]);
    });
    
    this.hubConnection.onreconnected(error => {
      console.assert(this.hubConnection.state === signalR.HubConnectionState.Connected);
      this.emitter.next([`Connection restored.`]);

    });

    this.hubConnection.on("UpdateRequest", (command: string) => {
       var msg:any = JSON.parse(command)
      console.log("Got Request ", command);
      if(this.jobMasterIsUpdting) {
        console.log("bypassing update request")
        return
      } else {
        this.dataStore.jobMasterRequiresUpdate = true;
       // let msg = 'jobmaster update required';
        this.emitter2.next(msg.sid)
      }
    });


    this.hubConnection.on("UpdateImportList", (command: string) => {
      console.log("Got Request " + command);
      var msg = JSON.parse(command)
      this.importRunName = msg.message;
      //eg  RunName Import    dmorris@pinnaclepowersvcs.com:202006291950252220
      this.dataStore.importListRequiresUpdate = true;

    });

    this.hubConnection.on("UpdateVehicleList", (command: string) => {
      console.log("UpdateVehicleList Got Request " + command);
      var msg = {"msg":"Update Vehicle List"}   
     
      this.emitter3.next([msg])

    });

    this.hubConnection.on("UpdateEventList", (command: string) => {
      console.log("UpdateEventList Got Request " + command);
      var msg = {"msg":"Update Event List"}   
      this.emitter5.next([msg]);
    //  this.emitter5.complete()

    });

    this.hubConnection.on("UpdateRepairOrderList", (command: string) => {
      console.log("UpdateRepairOrderList Got Request " + command);
      var msg = {"msg":"Update List"}   
      this.emitter4.next([msg])

    });

    //UpdateImportList

    this.hubConnection.on("BroadcastMessage", (name: string, retval: string) => {
      let cr = JSON.parse(retval);

      if (cr.sid === this.sessionID) name = 'me'
      let msg = name + ': ' + cr.message;
      this.emitter.next([msg])

        console.log("got something back from websockets...", name, cr)
    });

    this.hubConnection.on("echo", (name: string, retval: string) => {
      let cr = JSON.parse(retval);

      if (cr.sid === this.sessionID) name = 'me'
      let msg = name + ': ' + cr.message;
      this.emitter.next([msg])

      console.log("got something private from websockets...", name, cr)
    });


  }

  importRunName = '';


}