export const releaseInfo =
 {
 	branch: "Production",
 	lastCommit: "10/07/2024 18:11:01",
 	lastTag: "v4.0.0",
 	buildType: "beta",
 	company: "Pinnacle Power Services",
 	user: "dmorr",
 	compileTime: "10/07/2024 18:12:39"
 }
