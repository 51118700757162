import { Injectable, OnDestroy } from '@angular/core';
import { SiteReviewService } from './site-review.service';
import { Command } from 'app/models/command';

import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, from, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IdbService } from './idb.service';
import { ObservableStore } from '@codewithdan/observable-store';
import { Foreman, JobType, JobTypeActions, StoreState, UserStoreActions, ForemanStoreActions, OptionList, OptionListActions, UserListStoreActions } from 'app/interfaces/store-state';
import { User } from 'app/models/User';

export interface IOffice {
  officeID: number;
  officeName: string;
  officeCode?: string;
  officeNumber?: number;
}


@Injectable({
  providedIn: 'root'
})
export class DataStoreService extends ObservableStore<StoreState>  {
  private _generalForemanOptions;
  private _foremanOptions;
  public infractionOptions
  public officeList: IOffice[];
  public workDescriptionOptions;

  public jobMaster = { date: null, data: null };
  public tagMaster = { date: null, data: null };

  importListRequiresUpdate: boolean = true;
  vehicleListRequiresUpdate: boolean = true;

  projectManagers;

  private _users: any;
  get users() { return this._users; }
  set users(obj) { this._users = obj }


  // get superintendentOptions() {
  //   let sups = this._users.filter(u=>u.crewLevel==='SP');
  //   console.log('sups',sups)
  //   return sups}
  get superintendentOptions() {
    if(this._users === undefined){
      this.getAllCrewSupervisors().then(users => {
        let sups = users.filter(u=>u.crewLevel==='SP');
        console.log('sups from getAllCrewSupervisors in get',sups)
        return sups
      })
    } else {
      let sups = this._users.filter(u=>u.crewLevel==='SP');
      console.log('sups',sups)
      return sups
    }
  }
  get generalForemanOptions() { return this._generalForemanOptions; }
  set generalForemanOptions(obj) { this._generalForemanOptions = obj }

  get foremanOptions() { return this._foremanOptions; }
  set foremanOptions(obj) { this._foremanOptions = obj }

  public jobMasterRequiresUpdate = true;
  public jobMasterIsUpdating = false;
  public jobTemplates = [];

  public jobEventTypes = [];
  public jobScopeEventTypes = [];
  public subContractors = [];

  constructor(private ss: SiteReviewService, private http: HttpClient, private idb: IdbService) {
    super({ trackStateHistory: true, logStateChanges: true });

    this.initializeJobMaster()
    this.officeList = [];
    // this.getOfficeList().subscribe((retval: IOffice[]) => { this.officeList = retval; console.log('got offices', retval) });
  }

  async getUser(): Promise<User> {
    const state = this.getState();
    if (state && state.user) return state.user;
    const curUserData = JSON.parse(localStorage.getItem('curUserData'));
    return of(curUserData).toPromise();
  }
  setUser(user: User) {
    const state = this.getState();
    if (state && state.user) {
      this.setState({ user }, UserStoreActions.UpdateUser)
    }
    if (state && !state.user) {
      this.setState({ user }, UserStoreActions.AddUser)
    }
  }

  async superIntendentOptFunc(): Promise<any> {
    let sups;

    console.log("_user => ", this._users);
    if (this._users === undefined) {
      const users = await this.getAllCrewSupervisors();
      sups = users.filter(u => u.crewLevel === 'SP');
      console.log('sups from getAllCrewSupervisors in Func', sups);
    } else {
      sups = this._users.filter(u => u.crewLevel === 'SP');
      console.log('sups', sups);
    }
    return new Promise((resolve, reject) => resolve(sups));
  }

  jobEventsTypeData() {
    let cmd = new Command();
    cmd.procedure = 'cmdGetEventTypes';
    this.ss.command(cmd)
    .subscribe(retval => {
        //console.log('cmdGetEventTypes ' + retval);
        this.jobEventTypes =  retval;
        this.jobScopeEventTypes = retval.filter(events => events.name !== "Equipment");
        return retval;
    })
  }

  async getWorkType(): Promise<JobType[]> {
    const state = this.getState();
    if (state && state.jobTypes) {
      console.log("Using State Cached JobTypes")
      return state.jobTypes;
    }

    //console.log('Setting JobTypes State', state)

    let jobTypes = await (await this.ss.getJobTypes()).toPromise();
    this.workDescriptionOptions = jobTypes;
    this.setState({ jobTypes }, JobTypeActions.AddJobType)


    return of(jobTypes).toPromise();

  }

  async getOptionList(name: string = ''): Promise<OptionList[]> {
    var optionList = [];
    const state = this.getState();
    if (state && state.optionList) {
      console.log("Using State Cached OptionList")

      if (name === '') {
        return state.optionList;
      } else {
        return state.optionList.filter(obj => obj.optionName.toLowerCase() === name.toLowerCase());
      }
    }

    //console.log('Setting OptionList State', state)

    let options = await this.ss.getOptions('ALL');
    this.setState({ optionList: options }, OptionListActions.AddOptionList)

    if (name === '') {
      return of(options).toPromise();
    } else {
      var subset = options.filter(obj => obj.optionName.toLowerCase() === name.toLowerCase());
      return of(subset).toPromise();
    }
    //

  }


  async getAllCrewSupervisors(): Promise<any> {


    const state = this.getState();
    if (state && state.userList) {
      let ul = await state.userList;
      this._users = ul;
      console.log('returning userList from state service')

      return new Promise((resolve, reject) => resolve(ul));
    }
    //console.log('Setting userList State', state)

    let userList: User[] = [];

    userList = await this.ss.loadSupervisors()
      .pipe(
        //  map((users: any) => users.filter(obj => obj.crewLevel === 'SP')),
        // tap(users => console.log('userList Loaded', users))
      )
      .toPromise()

    userList.concat(userList)
    //console.log('loading userList into state service', userList);
    this.setState({ userList: userList }, UserListStoreActions.AddUserList)
    this._users = userList;

    return new Promise((resolve, reject) => resolve(userList));
  }



  async getAllForemen(): Promise<any> {

    const state = this.getState();
    if (state && state.foreman) {
      let fm = await state.foreman;
      console.log('returning foremen from state service')
      return fm;
    }
    var foremanOptions: Foreman[];
    var generalForemanOptions: Foreman[];


    let cmd1 = new Command();
    cmd1.procedure = 'cmdLookupTable';
    cmd1.addParameter('LookupName', 'Foreman');
    foremanOptions = await this.ss.command(cmd1).pipe(map((retval: Foreman[]) => {
      retval.forEach(f => {
        f.type = 'foreman';
      });

      return retval;
    }
    )).toPromise();



    let cmd2 = new Command();
    cmd2.procedure = 'cmdLookupTable';
    cmd2.addParameter('LookupName', 'GeneralForeman');
    generalForemanOptions = await this.ss.command(cmd2).pipe(map((retval: any) => {
      retval.forEach(f => {
        f.type = 'generalForeman';
      });
      return retval;
    })).toPromise();

    foremanOptions.concat(generalForemanOptions)
    console.log('loading foremen into state service', foremanOptions);
    this.setState({ foreman: foremanOptions }, ForemanStoreActions.AddForeman)

    return new Promise((resolve, reject) => resolve(foremanOptions));

  }



  async updateJobIndexDB(recArray) {
    let record = recArray;
    if (Array.isArray(record) && record.length === 1 && typeof record[0] === 'object') {
      record =  record[0];
    }
    console.log('updating record', record);
    let status = { updates: 0, inserts: 0 }
    return this.idb.select('jobs', record.jobID).then(resp => {
      if (resp) {
        this.idb.update('jobs', record.jobID, record).then(response => {
          status.updates = status.updates + 1;
          console.log('updated job ', response.jobID);
        });
      } else {
        this.idb.insert('jobs', record).then(response => {
          status.inserts = status.inserts + 1;
          //console.log('inserted job ', response.jobID); cmdJobsMasterUpdateChunked
        });

      }
    });

  }


  async updateJobMaster(data = null) {
    if (!data) data = this.jobMaster.data;
    if (!data) return false;
    console.log('got data', data)

    let status = { updates: 0, inserts: 0 }

    if (data) {

      data.forEach(record => {
        if (record.jobID) {
          this.idb.select('jobs', record.jobID).then(resp => {
            if (resp) {
              this.idb.update('jobs', record.jobID, record).then(response => {
                status.updates = status.updates + 1;
                console.log('updated job ', response.jobID);
              });
            } else {
              this.idb.insert('jobs', record).then(response => {
                status.inserts = status.inserts + 1;
                //console.log('inserted job ', response.jobID);
              })
            }
          }

          )
        }

      });
      localStorage.setItem('systemStatus', JSON.stringify({ jobMasterDate: new Date() }));
      this.jobMaster.date = new Date();
      console.log('updateJobMaster status:', status)
    }


  }

  getOfficeList() {
    var cmd = new Command();
    cmd.procedure = 'cmdOfficeList';
    return this.ss.command(cmd);
  }
  getGeneralForemen() {

    let url: string = this.ss.apiServer + '/api/cmd?sid=' + this.ss.sessionId + '&tracker=getGeneralForemen';
    let cmd = new Command();
    cmd.procedure = 'cmdLookupTable';
    cmd.addParameter('LookupName', 'GeneralForeman');
    return this.http.post(url, cmd).pipe(map((ret: any) => {
      this.generalForemanOptions = ret;
      return ret;
    }));
  }
  getForemen() {
    let url: string = this.ss.apiServer + '/api/cmd?sid=' + this.ss.sessionId + '&tracker=getForemen';;
    let cmd2 = new Command();
    cmd2.procedure = 'cmdLookupTable';
    cmd2.addParameter('LookupName', 'Foreman');
    return this.http.post(url, cmd2).pipe(map((ret: any) => {
      this.foremanOptions = ret;
      return ret;
    }));

  }



  getProjectManagers() {
    let cmd = new Command();
    cmd.procedure = "cmdProjectManagers";
    return this.ss.command(cmd)
      .subscribe((res => {
        let pmOptions = [];
        res.forEach(pm => {
          pmOptions.push(pm.fullname);
        });
        pmOptions.unshift("");
        //this.projectManagers = { values: pmOptions};
        this.projectManagers = pmOptions;
        console.log("PMs => ", this.projectManagers);
        return this.projectManagers;
      }));
  }

  getProjectManagersAsPromise(): Promise<string[]> {
    const cmd = new Command();
    cmd.procedure = "cmdProjectManagers";

    return new Promise((resolve, reject) => {
      this.ss.command(cmd)
        .subscribe(res => {
          const pmOptions = res.map(pm => pm.fullname); // Extract full names
          pmOptions.unshift(""); // Add empty option at the beginning
          resolve(pmOptions); // Resolve the promise with the list of names
        }, error => {
          reject(error); // Reject the promise with the error
        });
    });
  }





  /**
   * Sets a date for the refresh of the jobmaster storage
   * now using indexedDB store 'jobs'
   */
  public async initializeJobMaster() {

    await this.idb.initializeDB();
    this.jobMaster.data = [];
    this.jobMaster.date = new Date('1/1/1968');


  }

  public getJobTemplates(): Observable<any> {

    if (this.jobTemplates.length == 0) {
      //console.log('getting new templates from server')
      let cmd = new Command();
      cmd.procedure = "cmdTemplateList"
      return this.ss.command(cmd).pipe(map(ret => { this.jobTemplates = ret; return ret }));

    } else {
      console.log('using cached templates')
      return of(this.jobTemplates)
    }
  }


  public getJobStatusOptions(): Observable<string[]> {

    var options = [
      '',
      'Active',
      'Scheduled',
      'Completed',
      'Not Complete',
      'Complete on Arrival',
      'On Hold',
      'Canceled',
      'Canceled/Scheduled by GF (4Hr)',
      'Canceled/Scope Invalid (COA rate)',
      'Missing Documents',
      'Permit reviewing',
      'QA Failed',
      'QA Completed',]
     return of(options);
  }


  async getAllProfiles(): Promise<any> {

    let foremanOptions: Foreman[];
    let generalForemanOptions: Foreman[];
    let projectManagerOptions: any;

    let cmd1 = new Command();
    cmd1.procedure = 'cmdLookupTable';
    cmd1.addParameter('LookupName', 'Foreman');
    foremanOptions = await this.ss.command(cmd1).pipe(map((retval: Foreman[]) => {
      retval.forEach(f => {
        f.type = 'foreman';
      });

      return retval;
    }
    )).toPromise();

    let cmd2 = new Command();
    cmd2.procedure = 'cmdLookupTable';
    cmd2.addParameter('LookupName', 'GeneralForeman');
    generalForemanOptions = await this.ss.command(cmd2).pipe(map((retval: any) => {
      retval.forEach(f => {
        f.type = 'generalForeman';
      });
      return retval;
    })).toPromise();


    let cmd3 = new Command();
    cmd3.procedure = "cmdProjectManagers";
    projectManagerOptions = await this.ss.command(cmd3).pipe(map((retval: any) => {
      //console.log("cmdProjectManagers => ", retval)
      return retval;
    })).toPromise();

    let allProfiles = {
      generalForeman: generalForemanOptions,
      foreman: foremanOptions ,
      projectManagers: projectManagerOptions
    }

    this.projectManagers = allProfiles.projectManagers;
    this.generalForemanOptions = allProfiles.generalForeman;
    this.foremanOptions = allProfiles.foreman;

    return new Promise((resolve, reject) => resolve(allProfiles));

  }





  public loadAllDefaults(): [Observable<Object>, Observable<Object>] {
    let foremen, general;;

    if (!this.generalForemanOptions) {

      let url: string = this.ss.apiServer + '/api/cmd?sid=' + this.ss.sessionId;
      let cmd = new Command();
      cmd.procedure = 'cmdLookupTable';
      cmd.addParameter('LookupName', 'GeneralForeman');
      general = this.http.post(url, cmd);

      let cmd2 = new Command();
      cmd2.procedure = 'cmdLookupTable';
      cmd2.addParameter('LookupName', 'Foreman');
      foremen = this.http.post(url, cmd2);
      console.log('loading foremen from server')

      this.generalForemanOptions = general;
      this.foremanOptions = foremen;

      return [foremen, general];

    } else {
      foremen = of(this.foremanOptions)
      general = of(this.generalForemanOptions)
      console.log('loading foremen from cache')
      return [foremen, general];

    }
  }

  getSubContractors(){
    let cmd = new Command();
    cmd.procedure = "cmdGetSubContractors";
    cmd.addParameter("SubContractorID", 0);
    this.ss.command(cmd)
      .subscribe(res => {
      this.subContractors = res;
      let NASub = {
        name: 'NA',
        subContractorID: 0
      }
      this.subContractors.unshift(NASub);
        //console.log("data return from cmdGetSubContractors => ", res);
        //return this.subContractors;
    }, err => {

      console.warn("getSubContractors error ", err)
    });
  }

}
